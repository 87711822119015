.button {
  width: -moz-fit-content;
  width: fit-content;
  min-height: 2.8571rem;
  padding: 0.714rem 1.429rem;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0.3571rem;
  font-weight: var(--font-weight-bold);
  gap: 0.2857rem;
}

.button.has-icon {
  padding: 0.714rem 1.429rem 0.714rem 0.8571rem;
}

.button .button-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1.2857rem;
  height: 1.2857rem;
}

.button .button-icon path {
  fill: #ffffff;
}

.button.primary {
  color: #ffffff;
  background: var(--gradient-main);
}

.button.primary:hover,
.button.secondary:hover,
.button.tertiary:hover,
.button.warning:hover {
  opacity: 0.7;
}

.button.secondary {
  background-color: var(--secondary-color);
  color: #ffffff;
}

.button.tertiary {
  background: transparent;
  border: 1px solid var(--secondary-color);
}

.button.warning {
  background-color: var(--error-color);
  color: #ffffff;
}

.button.tertiary .button-icon path {
  fill: var(--secondary-color);
}

.button.warning .button-icon path {
  fill: #ffffff;
}

.button.disabled,
.button.disabled:hover {
  opacity: 0.3;
  cursor: unset;
}

.button-red .button.primary {
  background: unset;
  background-color: var(--status-red);
}