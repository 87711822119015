.page-layout-header .page-layout-header-action-buttons .icon-button.primary .icon svg path:first-child {
  fill: none;
}

.create-request .page-content {
  display: flex;
  flex-direction: column;
  gap: 1.4286rem;
}

.create-request .page-content .form-waybill-analysis-reason {
  display: flex;
  min-height: 13.7143rem;
  gap: 1.4286rem;
}

.create-request .page-content .form-waybill-analysis-reason .item {
  display: flex;
  width: 100%;
  height: 100%;
}

.create-request
  .page-content
  .form-waybill-analysis-reason
  .item.analysis-reason,
.create-request .page-content .form-waybill-analysis-reason .item.waybill {
  display: flex;
  gap: 1.2rem;
  flex-direction: column;
}

.create-request
  .page-content
  .form-waybill-analysis-reason
  .item.analysis-reason
  .form-field,
.create-request
  .page-content
  .form-waybill-analysis-reason
  .item.analysis-reason
  .form-field
  .text-area {
  flex: 1;
}

.create-request .create-request-action-btns {
  display: flex;
  justify-content: flex-end;
  gap: 1.1429rem;
  margin-top: 1rem;
}

.create-request .create-request-action-btns .button {
  min-width: 14.2857rem;
}

@media only screen and (max-width: 768px) {
  .create-request .page-content .form-waybill-analysis-reason {
    flex-direction: column;
  }

  .create-request .create-request-action-btns .button {
    min-width: unset;
    flex: 1;
  }
}
