.association-tests-samples-container {
    display: flex;
    flex-direction: column;
}

.association-tests-samples-container .tests-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.association-tests-samples-container .tests-container .test-item {
    display: flex;
    align-items: center;
    padding-right: 1rem;
}

.association-tests-samples-container .mobile-table-row .status-cell .tag {
    width: 100%;
    border-radius: 0.7143rem 0.7143rem 0 0;
}

.association-tests-samples-container .table-container .material-container .material-description {
    color: var(--grey-palette-01)
}

.association-tests-samples-container .strength-shrinkage-tests {
    display: flex;
    align-items: center;
    gap: .4286rem;
    font-weight: var(--font-weight-bold);
    padding-top: .4286rem;
}

.association-tests-samples-container .strength-shrinkage-tests>div {
    display: flex;
    align-items: center;
}

.association-tests-samples-container .total-cost-container {
    display: flex;
    justify-content: end;
    padding-top: .2857rem;
    align-items: center;
}

.association-tests-samples-container .total-cost-container .label {
    font-weight: var(--font-weight-bold);
    padding-right: .3571rem;
}

.association-tests-samples-container .total-cost-container .cost-description {
    font-size: 1.2857rem;
    font-weight: var(--font-weight-bold);
}

@media only screen and (max-width: 768px) {
    .association-tests-samples-container {
        gap: .8571rem;
    }

    .association-tests-samples-container .strength-shrinkage-tests {
        padding-top: 0;
    }

    .association-tests-samples-container .total-cost-container {
        padding-top: 0;
    }
}