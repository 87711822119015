.card-button {
  min-height: 24.1429rem;
  max-width: 37.1429rem;
  padding: 3.1429rem 2.8571rem 2.8571rem 2.8571rem;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.7143rem;
  font-size: 4.2857rem;
  font-weight: var(--font-weight-bold);
  position: relative;
  color: #ffffff;
  background: var(--gradient-main);
  gap: 1.1429rem;
}

.card-button .text {
  width: 90%;
}

.card-button .arrow {
  display: flex;
  align-items: end;
}

.card-button .card-button-icon {
  display: flex;
  align-items: end;
  justify-content: end;
  position: absolute;
  right: 2.8571rem;
  bottom: 2.8571rem;
  width: 7.1429rem;
  height: 7.1429rem;
  opacity: 0.2;
}

.card-button .card-button-icon svg {
  width: 100%;
  height: auto;
}

.card-button .card-button-icon path {
  fill: #ffffff;
}

.card-button:hover {
  opacity: 0.7;
}

.card-button.disabled,
.card-button.disabled:hover {
  opacity: 0.3;
  cursor: unset;
}

@media only screen and (max-width: 1024px) {
  .card-button {
    min-height: 17.1429rem;
    padding: 2.1429rem 1.8571rem 1.8571rem 1.8571rem;
    font-size: 3.1429rem;
  }

  .card-button .card-button-icon {
    right: 1.8571rem;
    bottom: 1.8571rem;
    width: 4.2857rem;
    height: 4.2857rem;
  }
}

@media only screen and (max-width: 768px) {
  .card-button {
    min-width: 100%;
    max-width: unset;
    min-height: 12.8571rem;
  }
}

@media only screen and (max-width: 425px) {
  .card-button {
    font-size: 2.2857rem;
  }
}
