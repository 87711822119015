.edit-request-sample {
  width: 34.2857rem;
}

.edit-request-sample .edit-request-sample-form {
  display: flex;
  flex-direction: column;
  gap: 1.7143rem;
}

.edit-request-sample .popup-buttons {
  width: calc((100% - 1.1429rem) / 2);
  margin-left: auto;
}

@media only screen and (max-width: 425px) {
  .edit-request-sample .popup-buttons {
    width: 100%;
  }
}