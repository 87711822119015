.tab-menu {
  display: flex;
  flex-direction: column;
}

.tab-menu .tab-buttons {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--grey-palette-02);
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tab-menu .tab-buttons::-webkit-scrollbar {
  display: none;
}

.tab-menu .tab-buttons .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8571rem 1.1429rem;
  font-weight: var(--font-weight-bold);
  text-align: center;
  border-bottom: 2px solid transparent;
  transition: all 0.25s ease;
  position: relative;
}

.tab-menu .tab-buttons .tab:hover {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  cursor: pointer;
}

.tab-menu .tab-buttons .tab.active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

.tab-menu .tab-buttons .tab .options-counter {
  position: absolute;
  top: 0.1429rem;
  right: 0.2857rem;
  color: var(--text-main-color);
  font-weight: var(--font-weight-bold);
}

.tab-menu .tab-content {
  padding: 1.2857rem 0.8571rem;
}
