.test-environment-message {
    background-color: red; 
    padding: .2857rem 0;
}

.test-environment-message .description {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    color: white;
}