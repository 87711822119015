.page-layout.employees-home .button.import-employees {
    min-width: 9.5rem;
}

.page-layout.employees-home .page-content {
    display: flex;
    flex-direction: column;
}

.page-layout.employees-home .text-input.search-box {
    width: 19.7857rem;
    margin-bottom: .8571rem;
}

@media only screen and (max-width: 425px) {
    .page-layout.employees-home .text-input.search-box {
        width: 100%;
    }
  }

