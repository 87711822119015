.icon-button {
  height: 2.1429rem;
  max-height: 2.1429rem;
  width: 2.1429rem;
  max-width: 2.1429rem;
  padding: 0.4286rem;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0.3571rem;
  font-weight: var(--font-weight-bold);
  gap: 0.2857rem;
}

.icon-button .icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.icon-button .icon path {
  fill: #ffffff;
}

.icon-button.primary {
  color: #ffffff;
  background: var(--gradient-main);
}

.icon-button.primary:hover,
.icon-button.secondary:hover,
.icon-button.tertiary:hover {
  opacity: 0.7;
}

.icon-button.secondary {
  background-color: var(--secondary-color);
  color: #ffffff;
}

.icon-button.tertiary {
  border: 1px solid var(--secondary-color);
}

.icon-button.tertiary .icon path {
  fill: var(--secondary-color);
}

.icon-button.disabled {
  opacity: 0.3;
  pointer-events: none;
}
