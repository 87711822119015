.page-layout-header .page-layout-header-action-buttons .icon-button.primary .icon svg path:first-child {
  fill: none;
}

.request-details .page-content {
    display: flex;
    flex-direction: column;
    gap: 1.4286rem;
}

.request-details .page-content .form-waybill-analysis-reason-container {
    display: flex;
    min-height: 10rem;
    gap: 1.4286rem;
}

.request-details .page-content .form-waybill-analysis-reason-container .item {
    display: flex;
    width: 100%;
    height: 100%;
}

.request-details .page-content .form-waybill-analysis-reason-container .item.analysis-reason,
.request-details .page-content .form-waybill-analysis-reason-container .item.waybill {
    display: flex;
    gap: 1.2rem;
    flex-direction: column;
}

.request-details .page-content .form-waybill-analysis-reason-container .item.waybill .waybill-info {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: 16px;
    grid-column-gap: 4.2857rem;
}

.popup-container.system-popup.warning-popup.cancel-request-warning-popup .popup-buttons .button.primary {
    background: var(--status-red);
}

@media only screen and (max-width: 768px) {
    .request-details .page-content .form-waybill-analysis-reason-container {
        flex-direction: column;
    }
  }