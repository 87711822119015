.page-layout.test-types-home .text-input.search-box {
    width: 19.7857rem;
    margin-bottom: .8571rem;
}

.page-layout.test-types-home .button.create-test-type-btn {
    min-width: 9.5rem;
}

.popup-container.system-popup.warning-popup.disable-test-type-popup .popup-buttons .button.primary {
    background: var(--status-red);
}

  @media only screen and (max-width: 425px) {
    .page-layout.test-types-home .text-input.search-box {
        width: 100%;
    }
  }