.mobile-table-row {
  padding: .8571rem .5714rem .8571rem .8571rem;
  border-radius: 0.7143rem;
  display: flex;
  flex-direction: column;
  gap: .5714rem;
  background-color: var(--grey-palette-03);
  position: relative;
}

.mobile-table-row.highlighted {
    background-color: var(--status-green-transparency-3)
  }

.mobile-table-row:has(.status-cell) {
  padding-top: 2.1429rem;
}

.mobile-table-row .status-cell {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.mobile-table-row .mobile-table-row-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: .2857rem;
}

.mobile-table-row .mobile-table-row-header.align-center {
  align-items: center;
}

.mobile-table-row .mobile-table-row-header .primary-cells {
  display: flex;
  flex-direction: column;
  gap: .5714rem;
}

.mobile-table-row .mobile-table-row-header .mobile-table-row-header-btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: .4286rem;
}
