.page-layout-header .page-layout-header-action-buttons .analyse-btn {
  background-color: var(--status-blue);
}

.page-layout-header .page-layout-header-action-buttons .reject-btn {
  background-color: var(--status-red);
}

.page-layout-header .page-layout-header-action-buttons .onhold-btn {
  color: var(--text-main-color);
  background-color: var(--status-yellow);
  text-wrap: nowrap;
}

.page-layout-header .page-layout-header-action-buttons .conclude-btn {
  background-color: var(--status-green);
}

.page-layout-header .page-layout-header-action-buttons .revert-btn {
  background-color: var(--status-grey);
}

.page-layout-header .page-layout-header-action-buttons .icon-button.secondary.onhold-btn .icon svg path {
  fill: var(--secondary-color);
}

.page-layout-header .page-layout-header-action-buttons .icon-button.secondary.onhold-btn .icon svg path:first-child,
.page-layout-header .page-layout-header-action-buttons .icon-button.secondary.conclude-btn .icon svg path:first-child {
  fill: none;
}

.sample-details .page-content {
  flex-direction: row;
  gap: 1.4286rem;
}

.sample-details .page-content .sample-data-and-control {
  display: flex;
  flex-direction: column;
  gap: 1.4286rem;
  flex: 1;
}

.sample-details .page-content .sample-data-and-control .sample-data {
  display: flex;
  flex-direction: column;
  gap: 1.1429rem;
}

.sample-details .page-content .sample-data-and-control .sample-data .sample-data-header {
  display: flex;
  flex-direction: column;
  gap: .2857rem;
}

.sample-details .page-content .sample-data-and-control .sample-data .sample-data-header .main {
  display: flex;
  justify-content: space-between;
}

.sample-details .page-content .sample-data-and-control .sample-data .sample-data-header .main .status-info {
  display: flex;
  align-items: center;
  gap: 0.4286rem;
}

.sample-details .page-content .sample-data-and-control .sample-data .sample-data-header .main .status-info .repeated-sample {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 1.0714rem;
  height: 1.0714rem;
  border-radius: 50%;
  background-color: var(--secondary-color);
  color: #ffffff;
  cursor: default;
  font-weight: var(--font-weight-bold);
  margin-right: 0.4286rem;
}

.sample-details .page-content .sample-data-and-control .sample-data .sample-data-header .sub-header-sample-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-container.system-popup.warning-popup.remove-c04-button .popup-buttons .button.primary {
  background: var(--status-red);

}



.sample-details .page-content .sample-data-and-control .sample-data .sample-data-header .sub-header-sample-info .created-date {
  color: var(--grey-palette-01);
  font-weight: var(--font-weight-bold);
}

.sample-details .page-content .sample-data-and-control .sample-data .sample-data-header .sub-header-sample-info .grow1 {
  display: flex;
  flex-grow: 1;
}

.sample-details .page-content .sample-data-and-control .sample-data .sample-data-header .sub-header-sample-info .repeated {
  color: #f5bf91;
  font-weight: bold;
}

.sample-details .page-content .sample-data-and-control .sample-data .sample-data-header .sub-header-sample-info .edit-time {
  display: flex;
}

.sample-details .page-content .sample-data-and-control .sample-data .sample-data-content {
  display: flex;
  flex-direction: column;
  gap: 1.7143rem;
}

.sample-details .page-content .sample-data-and-control .sample-data .sample-data-content .sample-data-content-info {
  display: flex;
  flex-direction: column;
  gap: 1.1429rem;
}

.sample-details .page-content .sample-data-and-control .sample-data .sample-data-content .sample-data-content-info>div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-row-gap: 1.1429rem;
  grid-column-gap: 4.2857rem;
}

.sample-details .page-content .sample-data-and-control .sample-data .sample-data-content .sample-data-content-info .control04 {
  display: flex;
  color: #f5bf91;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
}

/* .sample-details
  .page-content
  .sample-data-and-control
  .sample-data
  .sample-data-content
  .sample-data-content-info
  .controls04-pending
  .controls04-pending-grid-container
  > div,
.sample-details
  .page-content
  .sample-data-and-control
  .sample-data
  .sample-data-content
  .sample-data-content-info
  .last-control04-executed
  .last-control04-executed-grid-container
  > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column-gap: 4.2857rem;
} */

/* .sample-details
  .page-content
  .sample-data-and-control
  .sample-data
  .sample-data-content
  .sample-data-content-info
  .controls04-pending-grid-container
  .pending-grid-item,
.sample-details
  .page-content
  .sample-data-and-control
  .sample-data
  .sample-data-content
  .sample-data-content-info
  .last-control04-executed-grid-container
  .executed-grid-item {
  padding-bottom: 0.2857rem;
} */

/* .sample-details
  .page-content
  .sample-data-and-control
  .sample-data
  .sample-data-content
  .sample-data-content-info
  .controls04-pending-grid-container
  .pending-grid-item
  .item-status,
.sample-details
  .page-content
  .sample-data-and-control
  .sample-data
  .sample-data-content
  .sample-data-content-info
  .last-control04-executed-grid-container
  .executed-grid-item
  .item-status {
  min-width: 10rem;
} */

/* .sample-details
  .page-content
  .sample-data-and-control
  .sample-data
  .sample-data-content
  .sample-data-content-info
  .controls04-pending-grid-container
  .pending-grid-item
  .item-redirect-icon:hover,
.sample-details
  .page-content
  .sample-data-and-control
  .sample-data
  .sample-data-content
  .sample-data-content-info
  .last-control04-executed-grid-container
  .executed-grid-item
  .item-redirect-icon:hover {
  cursor: pointer;
} */

.sample-details .page-content .sample-data-and-control .sample-data .sample-data-content .sample-data-action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sample-details .page-content .sample-data-and-control .sample-data .sample-data-content .sample-data-action-buttons .countdown-timer {
  display: flex;
  flex-direction: column;
  gap: 0.2857rem;
}

.sample-details .page-content .sample-data-and-control .sample-data .sample-data-content .sample-data-action-buttons .countdown-timer .timer-label {
  color: var(--grey-palette-01);
}

.sample-details .page-content .sample-data-and-control .control-card {
  display: flex;
  flex-direction: column;
  gap: 1.1429rem;
}

.sample-details .page-content .sample-data-and-control .control-card:has(.control-info:empty) {
  display: none;
}

.sample-details .page-content .sample-data-and-control .control-card .control-info {
  display: flex;
  flex-direction: column;
  gap: 1.1429rem;
}

.sample-details .page-content .sample-data-and-control .control-card .control-info .mobile-table-row .status-cell .tag {
  width: 100%;
  border-radius: 0.7143rem 0.7143rem 0 0;
}

.sample-details .page-content .sample-data-and-control .control-card .control-info .first-control04,
.sample-details .page-content .sample-data-and-control .control-card .control-info .executed-on-control {
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
}

.sample-details .page-content .sample-data-and-control .control-card .control-info .controls04-pending,
.sample-details .page-content .sample-data-and-control .control-card .control-info .last-control04-executed {
  display: flex;
  flex-direction: column;
  gap: 0.4286rem;
}

.sample-details .page-content .sample-data-and-control .control-card .control-info .controls04-pending .controls04-pending-title,
.sample-details .page-content .sample-data-and-control .control-card .control-info .last-control04-executed .last-control04-executed-title {
  /* font-weight: bold; */
  color: var(--grey-palette-01);
}

.sample-details .page-content .sample-data-and-control .control-card .control-info .control-table .table-row-cell {
  padding: 0.4286rem 0.7143rem;
  min-height: 3rem;
}

.sample-details .page-content .sample-request-date {
  min-width: 27.1429rem;
  display: flex;
  flex-direction: column;
  gap: 1.1429rem;
}

.sample-details .page-content .sample-request-date .request-date-info {
  display: flex;
  flex-direction: column;
  gap: 0.1429rem;
}

.sample-details .page-content .sample-request-date .request-date-info .request-date {
  font-weight: var(--font-weight-bold);
  color: #f5bf91;
}

.sample-details .page-content .sample-request-date .request-date-info .request-date-timestamp {
  color: var(--grey-palette-01);
}

.sample-details .page-content .sample-request-date .request-date-form {
  display: flex;
  gap: 1.1429rem;
}

.sample-details .page-content .sample-request-date .request-date-form .x {
  background-color: var(--secondary-color);
  width: 100px;
  height: 100px;
}

.sample-details .page-content .sample-request-date .request-date-form .form-field {
  flex: 1;
}

.sample-details .page-content .sample-request-date .request-date-form .request-date-form-btn {
  max-width: 2.8571rem;
  min-width: 2.8571rem;
  min-height: 2.8571rem;
  max-height: 2.8571rem;
  padding: 0.5714rem;
}

.sample-details .page-content .sample-request-date .request-date-form .request-date-form-btn svg path:first-child {
  fill: none;
}

@media only screen and (max-width: 1200px) {
  .sample-details .page-content {
    flex-direction: column;
  }

  .sample-details .page-content .sample-data-and-control {
    flex: none;
  }

  .sample-details .page-content .sample-data-and-control .sample-data {
    flex: none;
  }

  .sample-details .page-content .sample-request-date {
    min-width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .sample-details .page-content .sample-data-and-control .sample-data .sample-data-content>div {
    flex-direction: column;
  }
}

.popup-container.system-popup.warning-popup.analyse-sample-popup .popup-buttons .button.primary {
  background: var(--status-blue);
}