.popup-container.popup-add-test {
    min-width: 65vw;
}

.popup-container.popup-add-test .section-inputs {
    grid-column-gap: 1.1429rem;
}

.popup-container.popup-add-test .popup-content.create-test .popup-buttons.create-test-btns{
    justify-content: flex-end;
}

.popup-container.popup-add-test .popup-content.create-test .popup-buttons.create-test-btns .button{
    flex: unset;
    min-width: 9.5rem;
}

@media only screen and (max-width: 425px) {
    .popup-container.popup-add-test .popup-content.create-test .popup-buttons.create-test-btns .button{
        flex: 1;
        min-width: unset;
    }
  }