 .request-details-main-container {
     display: flex;
     flex-direction: column;
     gap: 1.1429rem;
 }

 .request-details-main-container .request-details-form {
     display: flex;
     flex-direction: column;
     gap: 1.1429rem;
 }

 .request-details-main-container .request-details-form .request-info-blocks {
     display: flex;
     flex-wrap: wrap;
     grid-row-gap: 16px;
     grid-column-gap: 4.2857rem;
 }

 .request-details-main-container .request-details-form .separator {
     font-weight: var(--font-weight-bold);
     color: var(--grey-palette-01);
     border-bottom: 1px solid rgba(185, 184, 184, 0.2);
     padding-bottom: 0.125rem;
     margin-top: 0.2857rem;
 }