.card-container.titled .header-container {
    display: flex;
    flex-direction: column;
}

.card-container.titled .header {
    display: flex;
    justify-content: space-between;
}

.card-container.titled .header-container .title-left {
    font-size: 1.2857rem;
    font-weight: var(--font-weight-medium);
}

.card-container.titled .header-container .subtitle{
    color: var(--grey-palette-01);
    font-weight: var(--font-weight-bold);
}