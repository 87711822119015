.view-requests-page .page-content {
  display: flex;
  flex-direction: column;
}

.view-requests-page .page-content .overview-header {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1.4286rem;
}

.view-requests-page .page-content .overview-header .overview-header-stats {
  display: grid;
  gap: 1.4286rem;
  grid-template-columns: repeat(4, 1fr);
}

.view-requests-page .page-content .overview-header .overview-header-actions {
  display: flex;
  flex: 1;
}

.view-requests-page .page-content .requests-list {
  display: flex;
  flex-direction: column;
  gap: 0.8571rem;
}

.view-requests-page .page-content .requests-list .mobile-table-row .status-cell .tag {
    width: 100%;
    border-radius: 0.7143rem 0.7143rem 0 0;
  }

@media only screen and (max-width: 1024px) {
  .view-requests-page .page-content .overview-header {
    grid-template-columns: 1fr;
    gap: 0.8571rem;
  }

  .view-requests-page .page-content .overview-header .overview-header-stats {
    grid-template-columns: repeat(2, 1fr);
    order: 2;
    gap: 0.8571rem;
  }

  .view-requests-page
    .page-content
    .overview-header
    .overview-header-stats
    .status-card-container {
    height: 4.2857rem;
    flex-direction: row;
    padding: 0 1.1429rem;
    gap: 1rem;
  }

  .view-requests-page
    .page-content
    .overview-header
    .overview-header-stats
    .status-card-container
    .status-card-value,
  .view-requests-page
    .page-content
    .overview-header
    .overview-header-stats
    .status-card-container
    .status-card-text {
    text-align: left;
    width: unset;
  }

  .view-requests-page
    .page-content
    .overview-header
    .overview-header-stats
    .status-card-container
    .status-card-value {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .view-requests-page .page-content .overview-header .overview-header-stats {
    grid-template-columns: repeat(2, 1fr);
  }

  .view-requests-page
    .page-content
    .overview-header
    .overview-header-stats
    .status-card-container {
    height: 3.2857rem;
    gap: 0.4286rem;
    padding: 0 0.8571rem;
  }

  .view-requests-page
    .page-content
    .overview-header
    .overview-header-stats
    .status-card-container
    .status-card-value {
    font-size: 1.4286rem;
  }
}
