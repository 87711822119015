.page-layout.samples-home .page-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5.7143rem;
}

.page-layout.samples-home .page-content .card-button {
  flex: 1;
}

@media only screen and (max-width: 1024px) {
  .page-layout.samples-home .page-content {
    gap: 2.8571rem;
  }
}

@media only screen and (max-width: 768px) {
  .page-layout.samples-home .page-content {
    flex-direction: column;
  }

  .page-layout.samples-home .page-content .card-button {
    flex: none;
  }
}
