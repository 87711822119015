.edit-request-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
}

.edit-request-details .one-column {
  width: calc((100% - 1.1429rem) / 2);
}

.edit-request-details .flex-row {
  display: flex;
  gap: 1.1429rem;
}

.edit-request-details .flex-row > * {
  flex: 1;
}

.edit-request-ar-transport {
  display: flex;
  flex-direction: row;
  gap: 1.1429rem;
}

.edit-request-ar-transport .edit-request-ar,
.edit-request-ar-transport .edit-request-transport {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1.1429rem;
}

.edit-request-ar .form-field,
.edit-request-ar .form-field .text-area {
  flex: 1;
}

@media only screen and (max-width: 425px) {
  .edit-request-details .flex-row {
    flex-direction: column;
  }

  .edit-request-details .one-column {
    width: 100%;
  }

  .edit-request-ar-transport {
    flex-direction: column;
  }
}