.popup-container.edit-samples .card-container.edit-sample-control04 {
  height: fit-content;
  background-color: var(--grey-palette-02);
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 0.7143rem;
}

.popup-container.edit-samples .card-container.edit-sample-control04 .content {
  display: flex;
  flex-direction: column;
  gap: 0.7143rem;
}

.popup-container.edit-samples
  .card-container.edit-sample-control04
  .content
  .control-checkbox {
  display: flex;
  flex-direction: column;
  gap: 0.5714rem;
}

/* .popup-container.edit-samples
  .card-container.edit-sample-control04
  .last-control04 {
  display: flex;
  justify-content: space-between;
} */

.popup-container.edit-samples
  .card-container.edit-sample-control04
  .controls04-last-pending {
  display: flex;
  flex-direction: column;
  gap: 0.4286rem;
}

.popup-container.edit-samples
  .card-container.edit-sample-control04
  .controls04-last-pending
  .last-control04-info {
  font-weight: var(--font-weight-bold);
  color: var(--primary-color);
}

/* .popup-container.edit-samples
  .card-container.edit-sample-control04
  .last-control04
  .redirect-icon:hover {
  cursor: pointer;
} */

.popup-container.edit-samples
  .card-container.edit-sample-control04
  .content
  .controls04-last-pending
  .controls04-last-pending-title {
  font-weight: bold;
}

.popup-container.edit-samples
  .card-container.edit-sample-control04
  .content
  .controls04-last-pending
  .pending-controls-table
  .table-row-cell,
.popup-container.edit-samples
  .card-container.edit-sample-control04
  .content
  .controls04-last-pending
  .last-control-table
  .table-row-cell {
  padding: 0.4286rem 0.7143rem;
  min-height: 3rem;
}

.popup-container.edit-samples
  .card-container.edit-sample-control04
  .content .mobile-table-row .status-cell .tag {
    width: 100%;
    border-radius: 0.7143rem 0.7143rem 0 0;
  }

/* .popup-container.edit-samples .card-container.edit-sample-control04 .content .controls04-pending .controls04-pending-details {
    display: flex;
    flex-direction: column;
    gap: 0.2857rem;
}

.popup-container.edit-samples .card-container.edit-sample-control04 .content .controls04-pending .pending-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup-container.edit-samples .card-container.edit-sample-control04 .content .controls04-pending .controls04-pending-details .pending-item-status {
    min-width: 8rem;
}

.popup-container.edit-samples .card-container.edit-sample-control04 .content .controls04-pending .controls04-pending-details .redirect-icon:hover {
    cursor: pointer;
} */

@media only screen and (max-width: 425px) {
  .popup-container.edit-samples .edit-sample-control04 {
    padding: 1rem 1rem 1.7143rem 1rem;
  }
}
