.tests-tab .tab-content {
    display: flex;
    flex-direction: column;
    gap: 0.8571rem;
    height: 17.8571rem;
    overflow-y: auto;
}

.popup-content .card-container.associate-tests-sample {
    display: flex;
    flex-direction: column;
    gap: .1429rem;
    padding: 1rem;
}

.popup-container .card-container.associate-tests-sample .header {
    color: var(--grey-palette-01);
    font-size: .8571rem;
}

.popup-container .card-container.associate-tests-sample .sample-info {
    color: var(--secondary-color);
    font-weight: bold;
    font-size: 1rem
}

.popup-container.popup-associate-tests-sample .selected-tests-info-container {
    display: flex;
    flex-direction: column;
    align-items: end;
    color: var(--grey-palette-01);
    font-size: .8571rem
}

.popup-container.popup-associate-tests-sample .filtered-by-warning {
    font-weight: var(--font-weight-bold);
}

.popup-container.popup-associate-tests-sample {
    min-width: 35vw
}