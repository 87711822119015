.page-layout.tests-home .button.create-test-btn,
.page-layout.tests-home .button.import-test-btn, 
.page-layout.tests-home .button.navigate-test-types-btn{
  min-width: 9.5rem;
}

.page-layout.tests-home .page-content {
  display: flex;
  flex-direction: column;
}

.page-layout.tests-home .text-input.search-box {
  width: 19.7857rem;
  margin-bottom: .8571rem;
}

.page-layout.tests-home .table-container .costs,
.page-layout.tests-home .table-container .procedures,
.page-layout.tests-home .table-container .duration,
.page-layout.tests-home .table-container .name {
  font-size: 0.8571rem;
  font-weight: var(--font-weight-regular);
}

.popup-container.system-popup.warning-popup.disable-test-popup .popup-buttons .button.primary {
  background: var(--status-red);
}

.page-layout.tests-home .table-cell.table-row-cell .check-icon path:last-child {
  fill: black;
}

@media only screen and (max-width: 425px) {
  .page-layout.tests-home .text-input.search-box {
      width: 100%;
  }
}