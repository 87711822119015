.deliver-samples-overview .page-content {
  display: flex;
  flex-direction: column;
}

.deliver-samples-overview .page-content .overview-header {
  width: 100%;
  /*   display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1.4286rem; */
}

.deliver-samples-overview .page-content .overview-header .overview-header-stats {
  display: grid;
  gap: 1.4286rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.deliver-samples-overview .page-content .overview-header .overview-header-actions {
  display: flex;
  flex: 1;
}

.deliver-samples-overview .page-content .last-samples-delivered {
  display: flex;
  flex-direction: column;
  gap: 0.8571rem;
}

.deliver-samples-overview .page-content .last-samples-delivered .material-container .material-description {
  color: var(--grey-palette-01)
}

.deliver-samples-overview .page-content .last-samples-delivered .repeated-c04 {
  display: flex;
  align-items: center;
  gap: .5714rem;
}

.section-container .employee-name {
  color: var(--grey-palette-01)
}

.deliver-samples-overview .page-content .last-samples-delivered .repeated-c04 .repeated-sample {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 1.0714rem;
  height: 1.0714rem;
  border-radius: 50%;
  background-color: var(--secondary-color);
  color: #ffffff;
  cursor: default;
  font-weight: var(--font-weight-bold);
}

.deliver-samples-overview .page-content .last-samples-delivered .table-row-cell .action-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.deliver-samples-overview .page-content .last-samples-delivered .mobile-table-row .status-cell .tag {
  width: 100%;
  border-radius: 0.7143rem 0.7143rem 0 0;
}

@media only screen and (max-width: 1024px) {
  .deliver-samples-overview .page-content .overview-header {
    grid-template-columns: 1fr;
  }
}