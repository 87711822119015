.popup-add-contact .warning-email-message {
    display: flex;
    align-items: center;
    gap: .4286rem;
    font-weight: var(--font-weight-bold);
    padding-top: .4286rem;
}

.popup-add-contact .warning-email-message>div {
    display: flex;
    align-items: center;
}