.create-request .page-content {
  display: flex;
  flex-direction: column;
  gap: 1.4286rem;
}

.create-request .page-content .create-request-form-container {
  display: flex;
  flex-direction: column;
  gap: 1.1429rem;
}

.create-request .page-content .create-request-form-container .create-request-form {
  display: flex;
  flex-direction: column;
  gap: 1.1429rem;
}

.create-request .page-content .create-request-form-container .create-request-form .request-info-inputs {
  display: grid;
  gap: 1.1429rem;
  grid-template-columns: repeat(4, 1fr);
}

.create-request .page-content .create-request-form-container .create-request-form .request-info-inputs .checkbox-container.registed-compass {
  min-height: 4.7464rem;
}

.create-request .page-content .create-request-form-container .create-request-form .separator {
  font-weight: var(--font-weight-bold);
  color: var(--grey-palette-01);
  border-bottom: 1px solid rgba(185, 184, 184, 0.2);
  padding-bottom: 0.125rem;
  margin-top: 0.2857rem;
}

.create-request .create-request-action-btns {
  display: flex;
  justify-content: flex-end;
  gap: 1.1429rem;
  margin-top: 1rem;
}

.create-request .create-request-action-btns-wf-pp {
  display: flex;
  justify-content: space-between;
  gap: 1.1429rem;
  margin-top: 1rem;
  align-items: center;
}

.create-request .create-request-action-btns-wf-pp .slurry-data-sheet-info{
  font-size: .8571rem;
  font-weight: bold;
  color: var(--success-color);
}

.create-request .create-request-action-btns-wf-pp .button,
.create-request .create-request-action-btns .button {
  min-width: 14.2857rem;
}

@media only screen and (max-width: 1024px) {
  .create-request .page-content .create-request-form-container .create-request-form .request-info-inputs {
    gap: 1.1429rem;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .create-request .page-content .create-request-form-container .create-request-form .request-info-inputs {
    gap: 1.1429rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .create-request .create-request-action-btns-wf-pp .button,
  .create-request .create-request-action-btns .button {
    min-width: unset;
    flex: 1;
  }
}

@media only screen and (max-width: 600px) {
  .create-request .page-content .create-request-form-container .create-request-form .request-info-inputs {
    gap: 1.1429rem;
    grid-template-columns: repeat(1, 1fr);
  }
}