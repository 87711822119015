.edit-request {
  min-width: 50vw;
}

.edit-request .edit-request-form {
  display: flex;
  flex-direction: column;
  gap: 1.7143rem;
}

.edit-request .popup-buttons {
  width: calc((100% - 1.1429rem) / 2);
  margin-left: auto;
}

@media only screen and (max-width: 425px) {
  .edit-request .popup-buttons {
    width: 100%;
  }
}