.large-button {
  width: 100%;
  height: 10rem;
  padding: 0.714rem 1.429rem;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  border-radius: 0.7143rem;
  font-size: 1.8571rem;
  font-weight: var(--font-weight-bold);
}

.large-button.primary {
  color: #ffffff;
  background: var(--gradient-main);
}

.large-button.primary:hover,
.large-button.secondary:hover,
.large-button.tertiary:hover {
  opacity: 0.7;
}

.large-button.secondary {
  background-color: var(--secondary-color);
  color: #ffffff;
}

.large-button.tertiary {
  background: transparent;
  border: 1px solid var(--secondary-color);
}

.large-button.disabled,
.large-button.disabled:hover {
  opacity: 0.3;
  cursor: unset;
}

@media only screen and (max-width: 1024px) {
  .large-button {
    height: 7.1429rem;
    font-size: 1.7143rem;
  }
}

@media only screen and (max-width: 768px) {
  .large-button {
    height: 5.7143rem;
    font-size: 1.5714rem;
  }
}
