.view-samples-page-filters {
  display: flex;
}

.view-samples-page-filters .filters {
  width: 100%;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr auto;
  gap: 0 1.4286rem;
}

.view-samples-page-filters .filters .upper-row-filters {
  display: flex;
  gap: 1.1429rem;
  flex-wrap: wrap;
}

.view-samples-page-filters .filters .bottom-row-filters {
  grid-column: span 2;
  display: flex;
  flex-wrap: wrap;
  gap: 1.1429rem;
  height: 0;
  overflow-y: hidden;
}

.view-samples-page-filters .filters .bottom-row-filters.expanded {
  height: auto;
  overflow-y: visible;
  margin-top: 1.1429rem;
}

.view-samples-page-filters .filters .bottom-row-filters .date-picker-width {
  width: 14.2857rem;
}

.view-samples-page-filters .filters .action-button-filters {
  justify-self: end;
  display: flex;
  justify-content: center;
  gap: 0.8571rem;
  margin-bottom: auto;
}

.view-samples-page-filters .filters .action-button-filters .view-more-filters-btn,
.view-samples-page-filters .filters .action-button-filters .reset-filters-btn {
  min-width: 2.8571rem;
  min-height: 2.8571rem;
}

@media only screen and (max-width: 768px) {
  .view-samples-page-filters .filters {
    gap: 0 .7143rem;
  }

  .view-samples-page-filters .filters .upper-row-filters {
    gap: .7143rem;
  }
  
  .view-samples-page-filters .filters .bottom-row-filters {
    gap: .7143rem;
  }

  .view-samples-page-filters .filters .action-button-filters {
    gap: .7143rem;
  }
}

@media only screen and (max-width: 425px) {
  .view-samples-page-filters .filters .upper-row-filters {
    flex-grow: 1;
  }

  .view-samples-page-filters .filters .form-field {
    width: 100%;
  }

  .view-samples-page-filters .filters .form-field .text-input {
    min-width: unset;
  }

  .view-samples-page-filters .filters .form-field .text-input .native-input {
    width: 100%;
  }

  .view-samples-page-filters .filters .bottom-row-filters .date-picker-width {
    width: 100%;
  }
}