.view-samples-page .page-content {
  display: flex;
  flex-direction: column;
}

.view-samples-page .page-content .overview-header-stats {
  width: 100%;
  display: grid;
  gap: 1.4286rem;
  grid-template-columns: repeat(5, 1fr);
}

.view-samples-page .page-content .samples-in-laboratory {
  display: flex;
  flex-direction: column;
  gap: 0.8571rem;
}

.view-samples-page .page-content .samples-in-laboratory .repeated-c04 {
  display: flex;
  align-items: center;
  gap: .5714rem;
}

.view-samples-page .page-content .samples-in-laboratory .repeated-c04 .repeated-sample {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 1.0714rem;
  height: 1.0714rem;
  border-radius: 50%;
  background-color: var(--secondary-color);
  color: #ffffff;
  cursor: default;
  font-weight: var(--font-weight-bold);
}

.view-samples-page
  .page-content
  .samples-in-laboratory .mobile-table-row .status-cell .tag {
    width: 100%;
    border-radius: 0.7143rem 0.7143rem 0 0;
  }

.view-samples-page .page-content .samples-in-laboratory .action-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}


.view-samples-page .page-content .material-container .material-description {
  color: var(--grey-palette-01)
}

.view-samples-page .page-content .section-container .employee-name{
  color: var(--grey-palette-01)
}

@media only screen and (max-width: 1024px) {
  .view-samples-page .page-content .overview-header {
    grid-template-columns: 1fr;
    gap: 0.8571rem;
  }

  .view-samples-page .page-content .overview-header .overview-header-stats {
    grid-template-columns: repeat(4, 1fr);
    order: 2;
    gap: 0.8571rem;
  }

  .view-samples-page
    .page-content
    .overview-header
    .overview-header-stats
    .status-card-container {
    height: 4.2857rem;
    flex-direction: row;
    padding: 0 1.1429rem;
    gap: 1rem;
  }

  .view-samples-page
    .page-content
    .overview-header
    .overview-header-stats
    .status-card-container
    .status-card-value,
  .view-samples-page
    .page-content
    .overview-header
    .overview-header-stats
    .status-card-container
    .status-card-text {
    text-align: left;
    width: unset;
  }

  .view-samples-page
    .page-content
    .overview-header
    .overview-header-stats
    .status-card-container
    .status-card-value {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .view-samples-page .page-content .overview-header .overview-header-stats {
    grid-template-columns: repeat(2, 1fr);
  }

  .view-samples-page
    .page-content
    .overview-header
    .overview-header-stats
    .status-card-container {
    height: 3.2857rem;
    gap: 0.4286rem;
    padding: 0 0.8571rem;
  }

  .view-samples-page
    .page-content
    .overview-header
    .overview-header-stats
    .status-card-container
    .status-card-value {
    font-size: 1.4286rem;
  }
}
