.create-request-type .create-request-type-actions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.4286rem;
  width: 100%;
  height: fit-content;
}

@media only screen and (max-width: 1024px) {
  .create-request-type .create-request-type-actions {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.1429rem;
  }
}

@media only screen and (max-width: 768px) {
  .create-request-type .create-request-type-actions {
    grid-template-columns: 1fr;
  }
}
