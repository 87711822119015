.request-card .request-info {
    display: flex;
    flex-direction: column;
    gap: 1.4286rem;
}

.request-card .request-card-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.request-card .request-info .mobile-table-row .status-cell .tag {
    width: 100%;
    border-radius: 0.7143rem 0.7143rem 0 0;
  }

.request-card .request-info .request-sample {
    display: flex;
    flex-direction: column;
    gap: 1.1429rem;
}