
.page-layout.materials-home .button.create-material,
.page-layout.materials-home .button.import-material {
    min-width: 9.5rem;
}

.page-layout.materials-home .page-content {
    display: flex;
    flex-direction: column;
}

.page-layout.materials-home .text-input.search-box {
    width: 19.7857rem;
    margin-bottom: .8571rem;
}

.page-layout.materials-home .table-cell.table-row-cell .check-icon path:last-child {
    fill: black;
}

.popup-container.system-popup.warning-popup.disable-material-popup .popup-buttons .button.primary {
    background: var(--status-red);
}

@media only screen and (max-width: 425px) {
    .page-layout.materials-home .text-input.search-box {
        width: 100%;
    }
  }