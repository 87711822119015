.correlate-lab-samples {
    min-width: 90vw;
    min-height: 57rem;
}

.correlate-lab-samples .lab-sample-details-card {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.correlate-lab-samples .lab-sample-details-card .request-sample-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-row-gap: 1.1429rem;
    grid-column-gap: 4.2857rem;
  }

.correlate-lab-samples .samples-to-correlate {
    display: flex;
    flex-direction: column;
    gap: 0.8571rem;
}

.correlate-lab-samples .samples-to-correlate .filters {
    width: 100%;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr auto;
  gap: 0 1.4286rem;
}

.correlate-lab-samples .samples-to-correlate .filters .upper-row-filters {
    display: flex;
    gap: 1.1429rem;
    flex-wrap: wrap;
  }
  
  .correlate-lab-samples .samples-to-correlate .filters .bottom-row-filters {
    grid-column: span 2;
    display: flex;
    flex-wrap: wrap;
    gap: 1.1429rem;
    height: 0;
    overflow-y: hidden;
  }
  
  .correlate-lab-samples .samples-to-correlate .filters .bottom-row-filters.expanded {
    height: auto;
    overflow-y: visible;
    margin-top: 1.1429rem;
  }

  .correlate-lab-samples .samples-to-correlate .filters .action-button-filters {
    justify-self: end;
    display: flex;
    justify-content: center;
    gap: 0.8571rem;
    margin-bottom: auto;
    height: 100%;
    align-items: flex-end;
  }


  
  .correlate-lab-samples .samples-to-correlate .filters .action-button-filters .view-more-filters-btn,
  .correlate-lab-samples .samples-to-correlate .filters .action-button-filters .reset-filters-btn {
    min-width: 2.8571rem;
    min-height: 2.8571rem;
  }

.correlate-lab-samples .samples-to-correlate .repeated-sample {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 1.0714rem;
    height: 1.0714rem;
    border-radius: 50%;
    background-color: var(--secondary-color);
    color: #ffffff;
    cursor: default;
    font-weight: var(--font-weight-bold);
    margin-right: 0.4286rem;
}

.correlate-lab-samples .samples-to-correlate .mobile-table-row .status-cell .tag {
    width: 100%;
    border-radius: 0.7143rem 0.7143rem 0 0;
  }

.correlate-lab-samples .popup-buttons {
    justify-content: flex-end;
}

.correlate-lab-samples .popup-buttons>div {
    flex: 0 14.2857rem;
}

@media only screen and (max-width: 768px) {
    .correlate-lab-samples .samples-to-correlate .filters {
      gap: 0 .7143rem;
    }
  
    .correlate-lab-samples .samples-to-correlate .filters .upper-row-filters {
      gap: .7143rem;
    }
    
    .correlate-lab-samples .samples-to-correlate .filters .bottom-row-filters {
      gap: .7143rem;
    }
  
    .correlate-lab-samples .samples-to-correlate .filters .action-button-filters {
      gap: .7143rem;
    }
  }
  
  @media only screen and (max-width: 425px) {
    .correlate-lab-samples .samples-to-correlate .filters .upper-row-filters {
      flex-grow: 1;
    }
  
    .correlate-lab-samples .samples-to-correlate .filters .form-field {
      width: 100%;
    }
  
    .correlate-lab-samples .samples-to-correlate .filters .form-field .text-input {
      min-width: unset;
    }
  
    .correlate-lab-samples .samples-to-correlate .filters .form-field .text-input .native-input {
      width: 100%;
    }
  }